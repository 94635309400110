<template>
  <div class="posts-showcase">
    <div
      v-if="!hasShowcase && !isEditing"
      class="create-showcase-box"
      @click="setIsEditing(true)">
      <span class="hint-text text">Showcase your best projects here!</span>
    </div>
    <!-- actual showcase section -->
    <div
      v-if="hasShowcase && !isEditing"
      class="full-showcase-display"
      :class="showcaseClass">
      <div class="blurb-box" :class="showcaseClass">
        <span class="blurb-text text" :class="showcaseClass">{{
          showcaseBlurb
            ? showcaseBlurb
            : `Look what ${userData.alias} has done!`
        }}</span
        ><pencil-icon
          class="small-icon"
          v-if="isPersonalView"
          @click="setIsEditing(true)"></pencil-icon>
      </div>
      <div class="showcase-posts-container" :class="showcaseClass">
        <div
          class="post-image"
          v-for="(post, i) in showcasePosts"
          :key="i"
          :class="'post-' + i">
          <post-simple-view
            :postData="post"
            :thumbnailOverride="i === 0 ? getImage(post) : undefined"
            :class="[
              isLargeImage(i) ? 'large-post-image' : '',
              showcaseClass,
            ]"></post-simple-view>
        </div>
      </div>
    </div>
    <!-- editing section -->
    <div class="showcase-edit-box" v-if="isEditing">
      <div class="editing-post-box">
        <div
          class="post-or-button-container"
          v-for="(post, i) in processedPostArray"
          :key="i">
          <div
            v-if="post == 'none'"
            class="add-post-container"
            @click="addPostToShowcaseModal = true">
            <span class="hint-text text">Add a thing</span>
          </div>
          <div class="tiny-post-box" v-else>
            <post-teaser
              :postData="post"
              :clickable="false"
              :noHover="true"
              :simplified="true"
              :thumbnailOverride="getThumbnailFromPostData(post)"></post-teaser>
            <div class="tiny-button-container">
              <button
                class="tiny-post-button change-button"
                :class="[submissionLoading ? 'disabled' : '']"
                @click="changePost(i)">
                change
              </button>
              <button
                class="tiny-post-button remove-button"
                :class="[submissionLoading ? 'disabled' : '']"
                @click="removePost(i)">
                remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="editing-section-right-side">
        <div class="editing-project-blurb">
          <textarea
            v-model="blurb"
            type="text"
            class="big-text-input"
            placeholder="talk a bit about your best work, if you want" />
        </div>
        <div class="button-container">
          <button
            class="button button-primary"
            @click="submitData"
            :class="[submissionLoading ? 'disabled' : '']">
            finish
          </button>
          <button
            class="button button-info"
            @click="isPublic = !isPublic"
            :class="[submissionLoading ? 'disabled' : '']">
            make {{ isPublic ? "invisible" : "visible" }}
          </button>
          <button
            class="button button-danger"
            @click="setIsEditing(false)"
            :class="[submissionLoading ? 'disabled' : '']">
            cancel
          </button>
          <div class="status-box">
            <p class="text">
              Current Status:
              <span class="status-text">{{
                this.isPublic ? "public" : "hidden"
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <ModalPopup
      :show="addPostToShowcaseModal"
      @close="addPostToShowcaseModal = false">
      <template v-slot:body>
        <div
          class="all-posts"
          v-if="userPosts !== undefined && userPosts.length > 0">
          <post-teaser
            v-for="post in userPosts"
            :key="post.id"
            :postData="post"
            :clickable="false"
            @click.prevent="() => addPostToShowcase(post)"></post-teaser>
        </div>
        <div class="no-posts-box" v-else>
          <span class="text">You have nothing posted</span>
        </div>
      </template>
      <template v-slot:buttons>
        <button
          class="button button-danger"
          @click="addPostToShowcaseModal = false">
          Cancel
        </button></template
      >
    </ModalPopup>
  </div>
</template>

<script>
import PostTeaser from "./posts/PostTeaser.vue";
import ModalPopup from "@/components/basic/ModalPopup.vue";
import PostSimpleView from "./posts/PostSimpleView.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "ProjectShowcase",
  components: {
    PostTeaser,
    ModalPopup,
    PostSimpleView,
    PencilIcon,
  },
  mixins: [imageMixin],
  props: {
    hasShowcase: {
      type: Boolean,
      default: false,
    },
    isPersonalView: {
      type: Boolean,
      default: false,
    },
    showcasePosts: {
      type: Array,
      default: undefined,
    },
    userPosts: {
      type: Object,
      default: undefined,
    },
    userData: Object,
    showcaseBlurb: String,
  },
  data() {
    return {
      isEditing: false,
      addPostToShowcaseModal: false,
      posts: [],
      changingPost: null,
      isPublic: true,
      blurb: "",
      submissionLoading: false,
      hasChanges: false,
    };
  },
  computed: {
    numOfPosts() {
      return this.showcasePosts ? this.showcasePosts.length : 0;
    },
    processedPostArray() {
      let array = Array(4)
        .fill("none")
        .map((x, i) => (this.posts && this.posts[i] ? this.posts[i] : "none"));
      return array;
    },
    showcaseClass() {
      return "posts-num-" + this.numOfPosts;
    },
  },
  mounted() {
    if (this.showcasePosts) {
      this.showcasePosts.forEach((x) => {
        this.posts.push(x);
      });
      if (this.showcaseBlurb) this.blurb = this.showcaseBlurb;
    }
  },
  methods: {
    getImage(post) {
      //find main image
      if (post.external_url) {
        if (post.external_url.match(/[/.](gif|jpg|jpeg|tiff|png|gifv)$/i)) {
          return post.external_url;
        } else if (
          post.external_url.match(
            /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?\w\?=]*)?/
          )
        ) {
          let code = post.external_url.match(
            /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?\w\?=]*)?/
          )[1];
          return `https://i.ytimg.com/vi/${code}/maxresdefault.jpg`;
        }
      }
      return post.image;
    },
    isLargeImage(i) {
      return i === 0 && (this.numOfPosts === 1 || this.numOfPosts === 4);
    },
    addPostToShowcase(post) {
      if (!this.posts) {
        this.posts = [];
      }
      if (this.changingPost !== null) {
        console.log("hi");
        this.posts.splice(this.changingPost, 1, post);
      } else {
        this.posts.push(post);
      }
      this.addPostToShowcaseModal = false;
      this.changingPost = null;
      this.hasChanges = true;
    },
    changePost(i) {
      console.log(i);
      this.changingPost = i;
      this.addPostToShowcaseModal = true;
      this.hasChanges = true;
    },
    removePost(i) {
      this.posts.splice(i, 1);
      let temp = this.posts;
      this.posts = [];
      requestAnimationFrame(() => {
        //force re-render
        this.posts = temp;
      });
      this.hasChanges = true;
    },
    submitData() {
      if (
        !this.hasChanges &&
        this.blurb === this.showcaseBlurb &&
        this.isPublic === this.hasShowcase
      ) {
        this.posts = [];
        this.setIsEditing(false);
        return;
      }
      this.submissionLoading = true;
      let data = {};
      if (this.hasChanges) {
        data["posts"] = this.posts;
      }
      if (this.blurb !== this.showcaseBlurb) {
        data["blurb"] = this.blurb;
      }
      if (this.isPublic !== this.hasShowcase) {
        data["isPublic"] = this.isPublic;
      }
      this.$emit("submitted", data);
      this.posts = [];
    },
    setVisible(bool) {
      this.isPublic = bool;
    },
    setIsEditing(bool) {
      if (bool) {
        if (this.showcasePosts) {
          this.posts = [];
          this.showcasePosts.forEach((x) => {
            this.posts.push(x);
          });
          if (this.showcaseBlurb) this.blurb = this.showcaseBlurb;
          this.isPublic = this.hasShowcase;
        }
      }
      this.isEditing = bool;
    },
    setIsLoading(bool) {
      this.submissionLoading = bool;
    },
    getThumbnailFromPostData(post) {
      if (post.album && post.album.sections.length > 0) {
        return post.album.sections[post.main_image].thumbnail;
      }
      if (post.thumbnail) {
        return post.thumbnail;
      }
      return undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
$showcase-box-height: 20rem;
.posts-showcase {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-showcase-box {
  height: 3rem;
  border: dashed $grey-300 5px;
  padding: 2rem;
  border-radius: $border-radius;
  margin: $item-margin;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.hint-text {
  color: $grey-400;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: lowercase;
}
.showcase-edit-box {
  height: $showcase-box-height;
  background-color: $grey-200;
  width: 100%;
  margin: $item-margin;
  border-radius: $border-radius;
  display: flex;
}
.editing-post-box {
  height: $showcase-box-height;
  width: 30%;
  display: flex;
  flex-direction: column;
  row-gap: $item-margin;
  padding: $item-margin;
  & > * {
    flex-grow: 1;
  }
}
.add-post-container {
  border: dashed $grey-400 3px;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 7rem;
  height: 100%;
  cursor: pointer;
  & > * {
    transition: color 0.3s;
  }
  &:hover {
    & > * {
      color: $primary;
    }
  }
}
.editing-section-right-side {
  flex-grow: 1;
  padding: $item-margin;
}
.big-text-input {
  width: 100%;
  height: $showcase-box-height/2;
  background-color: $white;
  border-radius: $border-radius;
  &:hover {
    &::placeholder {
      color: $primary-500;
    }
  }
  &::placeholder {
    text-align: center;
    line-height: $showcase-box-height/2;
    font-size: 1.3rem;
    font-weight: 600;
    color: $grey-400;
    opacity: 1;
    transition: color 1s;
  }
}
.post-or-button-container {
  height: 3rem;
}
.no-posts-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & > * {
    font-size: 2rem;
    color: $grey-300;
  }
}
.tiny-button-container {
  display: flex;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: $item-margin;
  flex-wrap: nowrap;
}
.tiny-post-button {
  width: 100%;
  background-color: transparent;
  border: solid $grey-500 1px;
  border-radius: $border-radius;
  color: $grey-500;
  font-weight: 300;
  cursor: pointer;
  &.disabled {
    border-color: $grey-300 !important;
    color: $grey-300 !important;
    pointer-events: none;
  }
}
.remove-button {
  border-color: $danger-300;
  color: $danger-300;
}
.change-button {
  border-color: $primary-300;
  color: $primary-300;
}
.full-showcase-display {
  margin-top: $item-margin;
  border-radius: $border-radius;
  background-color: $grey-200;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-areas: "text" "images";
  margin: $item-margin;
}
.showcase-posts-container {
  display: flex;
  justify-content: center;
  grid-area: images;
}
.blurb-box {
  width: 100%;
  padding: 0.5rem;
  background-color: $grey-200;
  padding-left: 2rem;
  display: flex;
  border-radius: $border-radius;
  justify-content: space-between;
  grid-area: text;
  margin: 1rem auto;
  &.posts-num-1,
  &.posts-num-2 {
    width: 25rem;
  }
}
.blurb-text {
  color: $primary-700;
  font-size: 1.3rem;
  font-weight: 300;
}
.small-icon {
  cursor: pointer;
  font-size: 1.2rem;
}
.simple-post-box {
  max-height: 10rem;
}
.large-post-image:not(.posts-num-4) {
  max-height: none;
  height: 17rem;
}
.post-0 {
  grid-area: post-0;
}
.post-1 {
  grid-area: post-1;
}
.post-2 {
  grid-area: post-2;
}
.post-3 {
  grid-area: post-3;
}
.all-posts {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  overscroll-behavior: contain;
}
@media screen and (max-width: 1200px) {
  .button-container {
    flex-wrap: wrap;
  }
  .full-showcase-display {
    margin: $item-margin;
    &.posts-num-4 {
      grid-template-areas: "all";
      align-items: flex-start;
      justify-content: left;
      width: 100%;
    }
  }
  .large-post-image {
    max-height: none;
    height: auto;
  }
  .blurb-box {
    max-width: 60%;
    &.posts-num-4 {
      margin: 1.5rem;
    }
  }
  .blurb-text {
    font-size: 1rem;
  }
  .showcase-posts-container {
    &.posts-num-4 {
      display: grid;
      grid-template-columns: 65% 35%;
      grid-template-rows: auto auto auto;
      grid-template-areas: "post-0 post-1" "post-0 post-2" "post-0 post-3";
    }
  }
  .post-0 {
    align-self: flex-end;
  }
}
@media screen and (max-width: 950px) {
  .blurb-box {
    width: auto;
    max-width: none;
    margin: 1rem auto;
    padding: 0.5rem;
    &.posts-num-4 {
      margin: 1rem;
    }
  }
  .blurb-text {
    font-size: 1rem;
  }
  .full-showcase-display {
    margin: $item-margin;
    &.posts-num-4 {
      grid-template-areas: "text" "images";
      align-items: center;
      justify-content: center;
    }
  }
  .showcase-posts-container {
    display: grid;
    &.posts-num-4 {
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
      grid-template-areas: "post-0 post-1" "post-2 post-3";
    }
    &.posts-num-3 {
      grid-template-columns: 50% 50%;
      grid-template-rows: 50% 50%;
      grid-template-areas: "post-0 post-0" "post-1 post-2";
      .simple-post-box {
        max-height: none;
      }
      .post-0 > {
        max-height: none;
        height: 15rem;
        width: 100%;
      }
    }
    &.posts-num-1,
    &.posts-num-2 {
      display: flex;
      flex-direction: column;
      .large-post-image {
        max-height: 15rem;
        height: auto;
      }
      .simple-post-box {
        max-height: 15rem;
      }
    }
  }
  .large-post-image {
    max-height: 10rem;
    height: auto;
  }
}
</style>
