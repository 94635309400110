<template>
  <div class="user-likes-box">
    <div class="followers-box info-box">
      <p class="followers-text info-box-text text">
        <span class="followers-num number">{{ styledFollowers }}</span>
        &nbsp;{{ followers === 1 ? "follower" : "followers" }}
      </p>
      <!-- <plus-icon
        class="small-icon plus-icon"
        :class="[followed ? 'followed' : '']"></plus-icon> -->
    </div>
    <div class="likes-box info-box">
      <p class="likes-text info-box-text text">
        <span class="likes-num number"> {{ styledLikes }} </span>&nbsp;{{
          likes === 1 ? "like" : "likes"
        }}
      </p>
      <!-- <heart-icon
        class="small-icon heart-icon"
        :class="[liked ? 'liked' : '']"></heart-icon> -->
    </div>
  </div>
</template>

<script>
// import PlusIcon from "vue-material-design-icons/PlusCircle.vue";
// import HeartIcon from "vue-material-design-icons/Heart.vue";
export default {
  name: "UserLikesBox",
  //   components: {
  //     PlusIcon,
  //     HeartIcon,
  //   },
  props: {
    likes: {
      type: Number,
      default: 0,
    },
    followers: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    styledLikes() {
      return this.likes > 999
        ? (this.likes / 1000).toPrecision(2) + "K"
        : this.likes;
    },
    styledFollowers() {
      return this.followers > 999
        ? (this.followers / 1000).toPrecision(2) + "K"
        : this.followers;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-likes-box {
  height: 3rem;
  display: flex;
  flex-direction: row;
  border-radius: $border-radius;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;
}
.info-box {
  height: 100%;
  display: flex;
  color: $black;
  justify-content: center;
  align-items: center;
  background-color: $grey-200;
  padding: 0 0.5rem;
}
.followers-box {
  border-radius: $border-radius 0 0 $border-radius;
  border: $grey-400 solid 1px;
}
.likes-box {
  border-radius: 0 $border-radius $border-radius 0;
  border: $grey-400 solid 1px;
  border-left: none;
}
.small-icon {
  padding: $item-margin;
}
.followed {
  color: $purple !important;
}
@media screen and (max-width: $switch-width) {
  .user-likes-box {
    height: 2rem;
  }
}
</style>
