<template>
  <div class="page-profile container">
    <div class="header">
      <div class="avatar">
        <img
          class="profile-image"
          v-if="hasProfileImage"
          :src="userData?.profile_image"
          alt="" />
        <img
          v-else
          src="../assets/svg/temp-default-profile.svg"
          alt=""
          class="default-profile profile-image" />
      </div>
      <div class="profile-info">
        <h2 class="alias">{{ name }}</h2>
        <h3 class="bio">{{ userData?.bio }}</h3>
      </div>
      <div class="action-buttons" v-if="!personalView">
        <button class="action-button button" @click="onFollowClick">
          <p class="button-text">follow</p>
          <plus-icon :class="{ followed: followed }"></plus-icon>
        </button>
        <button class="action-button button" @click="onHeartClick">
          <p class="button-text">like</p>
          <heart-icon
            class="small-icon heart-icon"
            :class="{
              liked: liked,
              'liked-animate': animateLike,
            }"></heart-icon>
        </button>
        <share-button
          class="button action-button"
          :name="userData.alias"
          type="user"
          :url="fullRoute"></share-button>
      </div>
      <div class="action-buttons" v-else>
        <pencil-icon @click="$router.push('/settings/profile')">></pencil-icon>
      </div>
      <div class="ext-social-links">
        <user-likes-box
          :likes="userData.likes"
          :followers="userData.followers"></user-likes-box>
        <a v-for="data in socialLinks" :key="data.name" :href="data.value"
          ><social-icon class="social-icon" :type="data.name"></social-icon
        ></a>
      </div>
    </div>
    <div class="rest-of-page">
      <div class="posts">
        <div class="project-showcase-section" v-if="userPosts.length">
          <project-showcase
            v-if="personalView || userData?.has_project_showcase"
            :isPersonalView="personalView"
            :hasShowcase="userData?.has_project_showcase"
            :userPosts="userPosts"
            :showcasePosts="userData?.project_showcase_posts"
            :showcaseBlurb="userData?.project_showcase_description"
            @submitted="updateShowcase"
            :userData="userData"
            ref="showcase"></project-showcase>
        </div>
        <div
          class="vertical-spacer"
          v-if="!userData?.has_project_showcase"></div>
        <div class="no-posts-display" v-if="!userPosts.length && !postsLoading">
          <span class="no-posts-message"
            >Looks like {{ userData.alias }} hasn't shown off anything! They're
            probably too busy.</span
          >
          <img
            src="../assets/svg/temp-no-posts-icon.svg"
            alt=""
            class="no-posts-icon" />
        </div>
        <posts-large-container
          ref="container"
          :category="sorting"
          :time="time"
          :filter="filter"
          @changeFilters="onChangeFilter"
          name="profile"
          v-else
          :posts="userPosts"
          @reachedEnd="onReachedEnd"></posts-large-container>
      </div>
      <div class="followed-posts-container">
        <div
          class="followed-posts-text-container"
          v-if="userData.following_posts?.length">
          <span class="followed-posts-text text">
            Some things {{ name }} follows:
          </span>
        </div>
        <post-teaser
          v-for="post in userData.following_posts"
          :key="post.id"
          :postData="post"></post-teaser>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import PostsLargeContainer from "../components/posts/PostsLargeContainer.vue";
import ProjectShowcase from "../components/ProjectShowcase.vue";
import PostTeaser from "../components/posts/PostTeaser.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import SocialIcon from "../components/basic/SocialIcon.vue";
import UserLikesBox from "../components/basic/UserLikesBox.vue";
import HeartIcon from "vue-material-design-icons/Heart.vue";
import PlusIcon from "vue-material-design-icons/PlusCircle.vue";
import { socialsMixin } from "@/mixins/socialsMixin.js";
import ShareButton from "@/components/socials/ShareButton.vue";
export default {
  name: "Profile",
  components: {
    PostsLargeContainer,
    ProjectShowcase,
    PostTeaser,
    PencilIcon,
    SocialIcon,
    UserLikesBox,
    HeartIcon,
    PlusIcon,
    ShareButton,
  },
  mixins: [socialsMixin],
  computed: {
    hasProfileImage() {
      return (
        this.userData &&
        this.userData.profile_image !== "" &&
        this.userData.profile_image !== null &&
        this.userData.profile_image !== undefined
      );
    },
    name() {
      if (!this.userData) return "";
      return !this.userData.alias || this.userData.alias === ""
        ? this.userData.username
        : this.userData.alias;
    },
    socialLinks() {
      if (!this.userData || !this.userData.social_links) return [];
      return Object.keys(this.userData.social_links)
        .filter((key) => this.userData.social_links[key] !== "")
        .map((key) => {
          let url = this.userData.social_links[key];
          if (key == "email") {
            url = "mailto:" + url;
          } else if (!url.startsWith("http")) {
            url = "https://" + url;
          }
          return {
            name: key,
            value: url,
          };
        });
    },
    fullRoute() {
      let route = this.$route;
      return new URL(route.href, window.location.href).href;
    },
  },

  data() {
    return {
      lastPostRecieved: 0,
      userData: "no changed",
      personalView: false,
      userPosts: [],
      sorting: "latest",
      time: "all",
      filter: "none",
      postsLoading: false,
      liked: false,
      followed: false,
      animateLike: false,
    };
  },
  methods: {
    notifyToLogIn() {
      this.$notify({
        title: "Sorry",
        body: "Please log in to do that.",
        type: "warning",
      });
    },
    onHeartClick() {
      if (this.liked) {
        this.liked = false;
        this.dislikeProfile(this.userData.id);
      } else {
        this.animateLike = true;
        this.likeProfile(this.userData.id);
        this.liked = true;
        setTimeout(() => {
          this.animateLike = false;
        }, 1000);
      }
    },
    onFollowClick() {
      if (!this.$store.state.isAuthenticated) {
        this.notifyToLogIn();
        return;
      }
      if (this.followed) {
        this.followed = false;
        this.unfollowProfile(this.userData.id);
      } else {
        this.followed = true;
        this.followProfile(this.userData.id);
      }
    },
    getUserPosts() {
      this.postsLoading = true;
      this.$store.commit("setLoading", true);
      return axios
        .get("/api/v1/posts/user/" + this.userData.username + "/", {
          params: {
            from: this.lastPostRecieved,
            time: this.time,
            filter: this.filter,
            sorting: this.sorting,
          },
        })
        .then((res) => {
          this.$store.commit("setLoading", false);
          this.postsLoading = false;
          console.log(res);
          if (res.data.is_end) {
            this.$refs.container.setLoadedActive(false);
          } else {
            this.$refs.container.setLoadedActive(true);
          }
          res.data.posts.forEach((post) => {
            this.userPosts.push(post);
            this.lastPostRecieved++;
          });
        });
    },
    onChangeFilter(data) {
      this.sorting = data.sorting || "latest";
      this.time = data.time || "all";
      this.filter = data.filter || "none";
      this.lastPostRecieved = 0;
      this.userPosts.splice(0);
      this.getUserPosts();
    },
    onReachedEnd() {
      console.log("reached the end, gonna load");
      this.$refs.container.setLoading(true);
      this.getUserPosts().then(() => {
        this.$refs.container.setLoading(false);
      });
    },
    updateShowcase(data) {
      console.log(data);
      let form = new FormData();
      if (data.blurb) {
        form.set("project_showcase_description", data.blurb);
      }
      if (data.posts) {
        form.set(
          "project_showcase_posts",
          data.posts.map((x) => x.id)
        );
      }
      if (data.isPublic !== undefined) {
        form.set("has_project_showcase", data.isPublic);
      }
      console.log(form);
      axios
        .patch("/api/v1/users/me/", form)
        .then((res) => {
          console.log(res.data);
          this.$store.commit("setUserData", res.data);
          this.userData = res.data;
          this.$refs.showcase.setIsEditing(false);
          this.$refs.showcase.setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          this.$refs.showcase.setIsLoading(false);
        });
    },
  },
  mounted() {
    this.getUserPosts();
    document.title = this.userData.alias + "'s profile | icandoathing";
    if (this.$store.state.isAuthenticated) {
      this.liked = this.$store.state.userData.liked_profiles.includes(
        this.userData.id
      );
      this.followed = this.$store.state.userData.followed_profiles.includes(
        this.userData.id
      );
    } else {
      if (localStorage.getItem("offline_liked_profiles")) {
        let profiles = JSON.parse(
          localStorage.getItem("offline_liked_profiles")
        );
        if (profiles[this.userData.id] == "liked") {
          this.liked = true;
        }
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    let username = to.params.username;
    this.userData = undefined;
    if (
      store.state.isAuthenticated &&
      username === store.state.userData.username
    ) {
      next("/profile/me");
      return;
    }
    if (
      store.state.isAuthenticated &&
      (username === "me" || username === store.state.userData.username)
    ) {
      this.userData = store.state.userData;
      this.personalView = true;
      store.dispatch("ping");
      this.userPosts = [];
      this.getUserPosts();
      document.title = this.userData.alias + "'s profile | icandoathing";
      next();
    } else if (
      !store.state.isAuthenticated &&
      localStorage.getItem("token") &&
      (username === "me" || username === store.state.userData.username)
    ) {
      //we havent initialized
      store.dispatch("initialize").then(() => {
        this.userData = store.state.userData;
        this.personalView = true;
        store.dispatch("ping");
        this.userPosts = [];
        this.getUserPosts();
        document.title = this.userData.alias + "'s profile | icandoathing";
        next();
      });
    } else if (username !== "me") {
      axios.get("api/v1/users/" + username + "/").then((res) => {
        this.userData = res.data;
        this.userPosts = [];
        this.getUserPosts();
        document.title = this.userData.alias + "'s profile | icandoathing";
        next();
      });
    } else {
      next({
        name: "Login",
        query: {
          to: "/user/me",
        },
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    let username = to.params.username;
    if (
      store.state.isAuthenticated &&
      username === store.state.userData.username
    ) {
      next("/user/me");
      return;
    }
    if (
      store.state.isAuthenticated &&
      (username === "me" || username === store.state.userData.username)
    ) {
      next((vm) => {
        vm.userData = store.state.userData;
        console.log(vm.userData);
        vm.personalView = true;
        store.dispatch("ping");
      });
    } else if (
      !store.state.isAuthenticated &&
      localStorage.getItem("token") &&
      (username === "me" || username === store.state.userData.username)
    ) {
      //we havent initialized
      store.dispatch("initialize").then(() => {
        next((vm) => {
          vm.userData = store.state.userData;
          vm.personalView = true;
          console.log(vm.userData);
          store.dispatch("ping");
        });
      });
    } else if (username !== "me") {
      axios.get("api/v1/users/" + username + "/").then((res) => {
        next((vm) => {
          vm.userData = res.data;
          console.log(vm.userData);
        });
      });
    } else {
      next({
        name: "Login",
        query: {
          to: "/user/me",
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$header-height: clamp(180px, 28vh, 250px);
$header-second-height: clamp(230px, 32vh, 310px);
.page-profile {
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  height: fit-content;
}
.header {
  background-color: $grey-200;
  display: grid;
  grid-template-columns: $header-height auto 8rem;
  grid-template-rows: $header-height auto;
  grid-template-areas: "avater name buttons" "links links links";
}
.avatar {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: $grey-500;
  padding: 0.3em;
  margin: 1rem;
  z-index: 1;
}
.profile-image {
  border-radius: 50%;
}
.profile-info {
  margin: 2rem 0rem;
  text-align: left;
  padding-left: 10%;
}
.alias {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  margin-bottom: 1.2rem;
  color: $primary-600;
}
.bio {
  font-weight: 500;
  font-size: 1rem;
  color: $primary-600;
}
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.action-button {
  display: flex;
  width: 6rem;
  color: $primary-700;
  border-color: $primary-700;
  max-height: 2.5rem;
  margin: 0.2rem 0;
  &::v-deep .button-text {
    flex-grow: 1;
    margin: 0;
    font-weight: 100;
    font-size: 1rem;
  }
}
.social-numbers-container {
  background-color: $grey-200;
  border-radius: 0 0 5rem 0;
  height: 14rem;
  padding-top: 6vh;
  padding-bottom: 0;
}
.ext-social-links {
  grid-area: links;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 0.3rem;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.numbers-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.numbers {
  text-align: end;
  padding-right: 0.1rem;
  font-weight: 700;
}
.labels {
  text-align: start;
  padding-left: 0.1rem;
  font-weight: 300;
}
.temp-square {
  width: 2rem;
  height: 2rem;
  background-color: $white;
  border-radius: 0.5rem;
}
.posts {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-posts-display {
  border: solid $grey-300 2px;
  width: 60%;
  height: clamp(100px, 60%, 300px);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-left: 5rem;
}
.no-posts-message {
  pointer-events: none;
  user-select: none;
  font-size: 1.5rem;
  color: $grey-300;
}
.no-posts-icon {
  user-select: none;
  height: 80%;
}

.project-showcase-section {
  width: 100%;
}
.followed-posts-container {
  display: flex;
  column-gap: 0.5rem;
  padding: $item-margin;
  margin: 2rem;
}
.followed-posts-container > * {
  max-height: 2.7rem;
  width: 14rem;
}
.followed-posts-text-container {
  width: 15rem;
}
.followed-posts-text {
  padding: 0 0.5rem;
  font-weight: 300;
}
.rest-of-page {
  display: flex;

  flex-direction: column;
}
.vertical-spacer {
  width: 100%;
  height: 3rem;
}
.social-icon {
  width: 3rem;
  height: 3rem;
}

@media screen and (max-width: $switch-width) {
  $header-height: 8rem;
  .header {
    height: $header-height * 2;
    display: grid;
    grid-template-columns: $header-height auto auto;
    grid-template-rows: $header-height $header-height;
    border-radius: 0 0 $header-height 0;
    grid-template-areas: "avatar name buttons" "links links links";
  }
  .avatar {
    margin: 0.5rem;
  }
  .alias {
    font-size: 2rem;
    margin: 0;
    line-height: 1;
  }
  .profile-info {
    margin-top: 0.7rem;
  }
  .bio {
    font-size: 1rem;
    margin: 0.2rem;
  }
  .ext-social-links {
    justify-content: flex-start;
    padding-left: 1rem;
    margin: 0;
    align-content: flex-end;
    row-gap: 0.5rem;
    padding-bottom: 1rem;
  }
  .social-numbers-container {
    height: $header-height;
    padding: 0;
    padding-left: 2rem;

    margin: 0;
    justify-content: left;
    align-items: left;
  }
  .numbers-container {
    justify-content: left;
  }
  .action-button {
    width: 5.5rem;
    font-size: 0.8rem;
    height: 2rem;
    margin: 0;
  }
  .action-buttons {
    row-gap: 0.1rem;
  }
  .posts {
    margin: $item-margin;
  }
  .no-posts-display {
    margin-left: 0;
  }
  .no-posts-message {
    font-size: 1rem;
  }
  .rest-of-page {
    flex-direction: column;
  }
  .followed-posts-container {
    display: none; //TODO maybe fix this
  }
  .project-showcase-section {
    min-height: none;
  }
  .vertical-spacer {
    height: 0;
  }
  .social-icon {
    width: 2rem;
    height: 2rem;
  }
}
</style>
