<template>
  <div
    class="simple-post-box"
    :class="clickable ? 'clickable' : ''"
    @click="goToPostPage()">
    <img
      v-if="hasImage"
      :src="thumbnailOverride ? thumbnailOverride : mainImageSrc"
      alt="" />
    <div class="title-box">
      <span class="title-text">{{ postData.title }}</span>
    </div>
  </div>
</template>

<script>
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "PostSimpleView",
  mixins: [imageMixin],
  props: {
    postData: Object,
    clickable: {
      type: Boolean,
      default: true,
    },
    thumbnailOverride: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      mainImageSrc: this.loadingImageSrc,
    };
  },
  computed: {
    hasImage() {
      return (
        this.postData.thumbnail !== null || this.postData.external_url !== null
      );
    },
  },
  methods: {
    goToPostPage() {
      if (!this.clickable) return;
      this.$router.push(
        "/thing/" + this.postData.id + "/" + this.postData.slug
      );
    },
  },
  mounted() {
    this.mainImageSrc = this.loadingImageSrc;
    let url = this.postData.thumbnail || this.postData.external_url;
    if (this.thumbnailOverride) {
      url = this.thumbnailOverride;
    }
    this.getSrc(url).then((src) => {
      this.mainImageSrc = src;
    });
  },
};
</script>

<style lang="scss" scoped>
.simple-post-box {
  aspect-ratio: 16/9;
  max-height: 15rem;
  overflow: hidden;
  position: relative;
  img {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  border-radius: $border-radius;
  &:hover {
    .title-text {
      color: $primary-300;
    }
  }
  &.clickable {
    cursor: pointer;
  }
}
.title-box {
  position: absolute;
  bottom: 0;
  background-color: rgba($primary-600, 0.5);
  width: 100%;
  padding: 0.5rem;
  backdrop-filter: blur(4px);
}
.title-text {
  font-family: "Bebas Neue", sans-serif;
  text-transform: uppercase;
  color: $white;
  opacity: 1;
  transition: 0.3s;
}
@media screen and (max-width: $switch-width) {
  .title-text {
    font-size: 0.9rem;
  }
  .title-box {
    padding: 0.25rem;
  }
}
</style>
